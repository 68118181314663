@import './variables.scss';

header {
	text-align:center;
	position:relative;
	padding-bottom:10px;
	@media (min-width:$laptop) {
		padding-bottom:0;
	}
	#logo {
		margin:20px auto;
	}
}
