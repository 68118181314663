@import 'https://use.typekit.net/uhb8pkq.css';
@import './variables.scss';
@import './mixins.scss';

body {
    margin:0;
    font-family: $area;
    font-size:14px;
    font-weight:600;
    letter-spacing:.002rem;
    line-height:1.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @media (min-width:$laptop) {
        font-size:16px;
    }
}

#root {
    width:100%;
    &.menu-open {
        overflow:hidden;
        position:fixed;
        @media (min-width:$laptop) {
            overflow:visible;
            position:unset;
        }
    }
}
img {
    max-width:100%;
    height:auto;
}

h1 {
    @include h1();
    @media (min-width:$laptop) {
        @include h1-desktop();
    }
}

.container {
    @include content-well();
}

.page-content {
    display:flex;
    flex-direction:column;
}

button {
    font-family:$area;
}