@import './variables.scss';
@import './mixins.scss';

.mid-journey-preloader {
    position:relative;
    .placeholder-callout {
        color:#fff;
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        z-index:1;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
        font-family:$montserrat;
        font-size:16px;
        font-weight:600;
        padding:45px;
        box-sizing: border-box;
        opacity: 0;
        transition: opacity 2s ease-in-out;
        &.fade-in {
            opacity: 1;
        }
        a {
            @include button();
            &:hover {
                @media (min-width:$laptop) {
                    @include button-hover();
                }
            }
        }
    }
}