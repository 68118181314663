@import './variables.scss';

#alert-banner {
	background: #000;
	color:#fff;
	text-transform:uppercase;
	font-size:10px;
	line-height:22px;
	letter-spacing:.17em;
	font-weight:800;
	text-align:center;
	padding:7px 0;
}