@import './variables.scss';

$offset: 3.5px;

.nav-menu {
	background:url(../img/menuBackground.svg) no-repeat center center;
	background-size: cover;
	transition:all .65s ease;
	overflow: hidden;
	position:absolute;
	z-index:1;
	top:100%;
	left:0;
	width:100%;
	height:10px;
	max-height:10px;
	@media (min-width:$laptop) {
		background:url(../img/heroBackground.svg) no-repeat top center;
		background-size: cover;
		position:unset;
		overflow:visible;
	}
	ul {
		list-style: none;
		opacity:0;
		padding:30px 0;
		margin:0;
		transition:all .5s ease;
		@media (min-width:$laptop) {
			opacity:1;
			position:absolute;
			top:50%;
			right:15px;
			transform:translate(0,-50%);
			text-align:left;
			padding:0;
		}
		li {
			color:#fff;
			@media (min-width:$laptop) {
				color:$dark-gray;
				line-height:32px;
			}
			&:before {
				content: '\203A';
				margin-right:5px;
				font-size:22px;
				font-family:$montserrat;
				font-weight:400;
			}
			a {
				color:#fff;
				text-decoration: none;
				font-family:$montserrat;
				font-weight:700;
				@media (min-width:$laptop) {
					color:$dark-gray;
					font-size:14px;
					letter-spacing:.02em;
				}
				&:hover {
					@media (min-width:$laptop) {
						color:$dark-purple;
						text-decoration:underline;
					}
				}
			}
		}
	}
	&.open {
		height:80vh;
		max-height:80vh;
		ul {
			opacity:1;
		}
		@media (min-width: $laptop) {
			height:10px;
			max-height:10px;
		}
	}
}
