/* breakpoints */

$tablet: 768px;
$laptop: 992px;
$desktop: 1200px;

/* font families */

$area: "area-normal", sans-serif;
$montserrat: "montserrat-alternates", sans-serif;
$verveine: "verveine", sans-serif;

/* grid structure */

$content-well-tablet: 600px;
$content-well-laptop: 768px;
$content-well-desktop: 992px;


/* colors */

$black: #000;
$dark-gray: #03010F;
$light-purple: #A5A8FF;
$medium-purple: #8C90FD;
$dark-purple: #765BFE;
$pink: #FF48FE;
$light-blue: #EFF2F7;