@import './variables.scss';

.accordions {
	padding:50px 0;
	.accordion-item {
		border-top:1px solid $dark-gray;
		margin-bottom: 20px;
		.accordion-header {
			cursor: pointer;
			padding: 20px;
			width: 100%;
			border: none;
			text-align: left;
			outline: none;
			font-size: 16px;
			font-family:$montserrat;
			line-height:calc(23/20);
			letter-spacing:.002rem;
			font-weight:600;
			background:url(../img/accordionIcon.svg) no-repeat center right;
			background-size:33px 41px;
			&[aria-expanded="true"] {
				background:url(../img/accordionIconOpen.svg) no-repeat center right;	
			}
			@media (min-width:$laptop) {
				font-size: 20px;				
			}
		}
		.accordion-content {
			max-height: 0;
			overflow: hidden;
			transition: all 0.5s ease-in-out;
			background:$light-blue;
			font-size:14px;
			font-weight:500;
			letter-spacing:.014rem;
			line-height:2;
			&.open {
				max-height: 2000px;
			}
		}
		.accordion-inner {
			padding: 20px;
		}
	}
}
