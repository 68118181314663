@import './variables.scss';

$offset: 3.5px;

.menu-toggle {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 8px;
  right: 15px;
  @media (min-width:$laptop) {
    display:none;
  }
  .bar {
    height: 2px;
    width: 100%;
    background-color: #fff;
    transition: all 0.3s ease;
  }
  &.open {
    .bar {
      &:nth-child(1) {
        transform: rotate(45deg) translate($offset, $offset);
      }
      &:nth-child(2) {
        transform: rotate(-45deg) translate($offset, -$offset);
      }
    }
  }
}
