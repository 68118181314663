@import './variables.scss';

.social-share {
  background:$black;
  border-top:1px solid #fff;
  @media (min-width:$tablet) {
    position:fixed;
    top:150px;
    left:0;
    height:auto;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
    border-top:none;
  }
  @media (min-width:$laptop) {
    top:250px;
  }
  #st-1 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    @media (min-width:$tablet) {
      flex-direction: column;
    }
    .st-btn {
      display:block !important;
    }
  }
}
