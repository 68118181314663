@import './variables.scss';

#hero {
	&.faqs {
		background:none;
		color:$black;
		@media (min-width:$tablet) {
			text-align:center;			
		}
	}
}