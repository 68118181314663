@import './variables.scss';
@import './mixins.scss';

#hero {
  background:url(../img/menuBackground.svg) no-repeat center center;
  background-size: cover;
  color:#fff;
  padding:30px 0;
  text-align:center;
  @media (min-width:$laptop) {
    background:url(../img/heroBackground.svg) no-repeat center center;
    background-size: cover;
  }
  button {
    @include button();
    background:transparent;
    color:#fff;
    border:1px solid #fff;
    margin-top:0;
    &:hover {
      @media (min-width:$laptop) {
        @include button-hover();
      }
    }      
  }
  

}