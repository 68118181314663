@import './variables.scss';
@import './mixins.scss';

.fullwidth-cta {
	padding:50px 0;
	background:url(../img/menuBackground.svg) no-repeat center center;
	background-size:cover;
	color:#fff;
	text-align:center;
	h2 {
		font-family:$montserrat;
		font-size:20px;
		font-weight:600;
		letter-spacing:.02rem;
		line-height:calc(40/28);
		@media (min-width:$laptop) {
			font-size:28px;			
			line-height:calc(23/28);
		}
	}
	a {
		@include button();
		background:transparent;
		color:#fff;
		border:1px solid #fff;
		margin-top:0;
		&:hover {
			@media (min-width:$laptop) {
				@include button-hover();
			}
		}
	}
}
